<template>
    <div style="height:100%;width:100%">
        <el-row :gutter="20">
            <el-col :span="8">

                <div class="grid-content bg-purple" style="padding: 20px;">
                    <el-form ref="addProjectFormData" :model="addProjectFormData" label-width="100px"
                        :rules="addProjectFormDataRules">
                        <el-form-item label="项目名称" prop="name">
                            <el-input v-model="addProjectFormData.name"></el-input>
                        </el-form-item>
                        <el-form-item label="所属公司">
                            <el-select v-model="addProjectFormData.corporation" placeholder="请选择">
                                <el-option v-for="item in enterpriseOptions" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="项目详情" prop="details">
                            <el-input v-model="addProjectFormData.details"></el-input>
                        </el-form-item>
                        <el-form-item label="项目地址" prop="site">
                            <el-input v-model="addProjectFormData.site"></el-input>
                        </el-form-item>

                        <el-form-item label="工程性质" prop="engineering">
                            <el-input v-model="addProjectFormData.engineering"></el-input>
                        </el-form-item>
                        <el-form-item label="工程阶段" prop="angineering">
                            <el-select v-model="addProjectFormData.angineering" placeholder="请选择">
                                <el-option v-for="item in projectOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="开工时间" prop="create_time">
                            <el-date-picker v-model="addProjectFormData.create_time" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="拟竣工时间" prop="update_time">
                            <el-date-picker v-model="addProjectFormData.update_time" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="所在地" prop="detaileds">
                            <el-cascader size="large" :options="options" v-model="addProjectFormData.detaileds" clearable />
                        </el-form-item>
                    </el-form>
                    <!-- <el-button @click="addDialogVisible = false">取 消</el-button> -->
                    <el-button type="primary" @click="addProject">确 定</el-button>
                </div>
            </el-col>
            <el-col :span="16">
                <div class="grid-content bg-purple" style="padding: 20px;">
                    <el-table :data="tableData1" border style="width: 100%" height="850">
                        <el-table-column prop="name" label="工程名称" width="180">
                        </el-table-column>
                        <el-table-column prop="angineering" label="工程阶段" width="180">
                        </el-table-column>
                        <el-table-column prop="engineering" label="工程性质">
                        </el-table-column>
                        <el-table-column prop="details" label="工程简介" show-overflow-tooltip="true">
                        </el-table-column>
                        <el-table-column prop="create_time" label="提交时间">
                        </el-table-column>
                        <el-table-column prop="Audit" label="审核状态">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <div v-if="isdealer == 'true'">
                                    <el-button type="success" v-if="scope.row.Audit == '待审核'" size="mini"
                                        @click="approved1(scope.row.id)">通过审核</el-button>

                                    <el-button size="mini" type="info" disabled v-else
                                        @click="approved1(scope.row.id)">通过审核</el-button>
                                </div>
                                <div v-if="isdealer == 'false'">
                                    <el-button type="success" v-if="scope.row.Audit == '等待系统审核'" size="mini"
                                        @click="approveds(scope.row.id)">通过审核</el-button>
                                    <el-button size="mini" type="info" disabled v-else
                                        @click="approveds(scope.row.id)">通过审核</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <el-dialog title="年费金额" :visible.sync="dialogVisible" width="25%" center>
            <div style="display: flex;align-items: center;">
                <div style="margin-right: 10px;">年费金额:</div>
                <div style="width: 250px;">
                    <el-input v-model="input" placeholder="请输入金额"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="approved">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import {
    listsOfCompanies,
    addProjectInfo1,
    approved,
    approveds,
    addProjectInfo
} from "../../request/project";

export default {
    data() {
        return {
            input: '',
            dialogVisible: false,
            projectOptions: [
                {
                    label: "基础施工阶段",
                    value: "基础施工阶段",
                },
                {
                    label: "主体施工阶段",
                    value: "主体施工阶段",
                },
                {
                    label: "装饰装修阶段",
                    value: "装饰装修阶段",
                },
            ], //项目阶段下拉框
            enterpriseOptions: [], //所属公司下拉框
            addProjectFormData: {
                name: "",
                corporation: "",
                details: "",
                site: "",
                create_time: "",
                update_time: "",
                engineering: "",
                angineering: "",
                detaileds: "",
            }, //添加项目信息表单
            addProjectFormDataRules: {
                name: [{ required: true, message: "请输入名称", trigger: "blur" }],
                details: [{ required: true, message: "请输入名称", trigger: "blur" }],
                site: [{ required: true, message: "请输入名称", trigger: "blur" }],
                engineering: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                ],
                angineering: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                ],
                detailed: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                ],
            },
            options: regionData, // 省市区三级联动
            tableData1: [], //展示项目审核信息表格
            isdealer: '',
            Review_id: '',
        }
    },

    components: {},

    computed: {},

    mounted() {
        this.isdealer = localStorage.getItem('isdealer')
        this.listsOfCompanies()
        this.addProject1()
    },

    methods: {
        //公司列表
        async listsOfCompanies() {
            let msg = {
                unique_identification: localStorage.getItem('uid')
            }
            const res = await listsOfCompanies(msg)
            console.log(res);
            this.enterpriseOptions = res.data.data
        },
        // 添加用户
        async addProject() {
            console.log(this.addProjectFormData);
            const res = await addProjectInfo(this.addProjectFormData);

            if (res.data.code == 200) {
                this.$message({
                    message: "添加人员成功",
                    type: "success",
                });
                this.addProjectFormData = {
                    name: "",
                    corporation: "",
                    details: "",
                    site: "",
                    create_time: "",
                    update_time: "",
                    engineering: "",
                    angineering: "",
                    detaileds: "",
                }
                this.addProject1()
            }
            this.$message({
                message: res.data.msg,
                type: "error",
            });
        },
        //获取项目审核列表
        async addProject1() {
            let msg = {
                unique_identification: localStorage.getItem('uid')
            }
            const res = await addProjectInfo1(msg);
            this.tableData1 = res.data.data
        },
        approved1(id) {
            this.Review_id = id
            this.dialogVisible = true
        },
        async approved() {
            let msg = {
                unique_identification: localStorage.getItem('uid'),
                Review_id: this.Review_id,
                Renewal: this.input,
            }
            const res = await approved(msg)
            if (res.data.code == 200) {
                this.$message({
                    message: res.data.msg,
                    type: 'success'
                });
                this.dialogVisible = false
                this.input = ''
                this.addProject1()
            } else {
                this.$message.error(res.data.msg);
            }
        },
        async approveds(id) {
            let msg = {
                unique_identification: id
            }
            const res = await approveds(msg)
            if (res.data.code == 200) {
                this.$message({
                    message: res.data.msg,
                    type: 'success'
                });
                this.addProject1()
            } else {
                this.$message.error(res.data.msg);
            }
        },
    }
}

</script>
<style  scoped></style>
<style>
.el-row {
    margin-bottom: 20px;

}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #eef0f1;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    height: 92vh;


}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
</style>